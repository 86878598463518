<template>
  <div
    class="permissionsBox item-b"
    v-loading="loadingData"
    element-loading-text="拼命加载中"
  >
    <!-- 组件 -->
    <FormItem
      :formList="formList"
      :btn="btn"
      @search="onSearch"
      @export="onExport"
      @reset="onReset"
      :btnObj="btnObj"
      :search="search"
    />
    <Table
      ref="table"
      :tableList="tableList"
      :tableData="tableData"
      @operation="onHandle"
      @currentNum="current"
      :total="total"
    />
    <!-- 抽屉模块 -->
    <el-drawer
      custom-class="drawer-class"
      :visible.sync="show"
      :show-close="false"
      :withHeader="false"
      :wrapperClosable="false"
    >
      <div class="drawer-box flex-col">
        <div class="title" v-if="!isEdit">
          <span class="rs"> </span> <span class="re"></span>新增报修<span
            class="ra"
          >
          </span>
          <span class="rb"> </span>
        </div>
        <div class="title" v-else>
          <span class="rs"> </span> <span class="re"> </span>
          编辑报修
          <span class="ra"> </span> <span class="rb"> </span>
        </div>
        <div class="table auto">
          <el-form
            :model="postFormData"
            size="small"
            :rules="postFormDataRules"
            label-position="left"
            label-width="auto"
            ref="addForm"
            :hide-required-asterisk="true"
          >
            <el-form-item label="公厕名称：" prop="videoName">
              <el-input
                v-if="!isEdit"
                placeholder="请输入公厕名称"
                v-model="postFormData.videoName"
                class="custom-submit-input"
              ></el-input>
              <span v-else>{{ postFormData.videoName }}</span>
            </el-form-item>
            <el-form-item label="申报人：" prop="videoModel">
              <el-input
                v-if="!isEdit"
                placeholder="请输入申报人："
                v-model="postFormData.videoModel"
                class="custom-submit-input"
              ></el-input>
              <span v-else>{{ postFormData.videoModel }}</span>
            </el-form-item>
            <el-form-item v-if="isEdit" label="申报时间：" prop="videoModel">
              <span>{{ postFormData.videoModel }}</span>
            </el-form-item>
            <el-form-item label="申报原因：" prop="videoModel">
              <el-input
                v-if="!isEdit"
                placeholder="请输入申报原因:"
                v-model="postFormData.videoModel"
                type="textarea"
                :rows="4"
                class="custom-submit-input"
              ></el-input>
              <span v-else>{{ postFormData.videoModel }}</span>
            </el-form-item>
            <el-form-item v-if="isEdit" label="维修人：" prop="videoModel">
              <el-input
                placeholder="请输入维修人："
                v-model="postFormData.videoModel"
                class="custom-submit-input"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="isEdit" label="维修详情：" prop="videoModel">
              <el-input
                placeholder="请输入维修详情:"
                v-model="postFormData.videoModel"
                type="textarea"
                :rows="4"
                class="custom-submit-input"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="站点类型：" prop="siteType">
              <el-select
                v-model="postFormData.siteType"
                placeholder="请选择站点类型"
                class="custom-submit-select"
                @change="handleRole"
              >
                <el-option
                  v-for="item in siteTypes"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="站点名称：" prop="siteName">
              <el-select
                v-model="postFormData.siteName"
                placeholder="请选择站点"
                class="custom-submit-select"
                @change="handleDepartment"
              >
                <el-option
                  v-for="item in departmentOptions"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备类型:" prop="videoType">
              <el-select
                v-model="postFormData.videoType"
                placeholder="请选择设备"
                class="custom-submit-select"
                @change="handleDepartment"
              >
                <el-option
                  v-for="item in videoTypeList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            
            <el-form-item label="设备通道：" prop="channelNo">
              <el-input
                v-if="!isEdit"
                placeholder="请输入设备通道"
                v-model="postFormData.channelNo"
                class="custom-submit-input"
              ></el-input>
              <span v-else>{{ postFormData.channelNo }}</span>
            </el-form-item> -->
          </el-form>
        </div>
        <div class="c-center btn">
          <el-button
            type="primary submit-primary"
            v-if="!isEdit"
            @click="saveAdd"
            >保存</el-button
          >
          <el-button type="primary submit-primary" v-else @click="update"
            >保存</el-button
          >
          <el-button type="info submit-info" @click="cancelAdd">取消</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import axios from "@/request";
export default {
  name: "WashroomSummarizing",
  data() {
    return {
      loadingData: true,
      formList: [
        {
          props: "toiletName",
          el: "el-input",
          label: "设备名称",
          placeholder: "请输入公厕名称",
        },
      ],
      btnObj: {
        search: "查询",
        export: "导出",
        reset: "重置",
      },
      btn: "search,export,reset",
      tableList: [
        {
          prop: "index",
          label: "序号",
          width: "60",
        },
        {
          prop: "toiletName",
          label: "公厕名称",
          width: "",
        },

        {
          prop: "score",
          label: "公厕得分",
          width: "",
        },
        {
          label: "氨气浓度",
          width: "",
          multistage: true,
          options: [
            {
              prop: "manAmmoniaGas",
              label: "男厕",
              width: "",
              color(val) {
                return "#3B7FFF";
              },
            },
            {
              prop: "womanAmmoniaGas",
              label: "女厕",
              width: "",
              color(val) {
                return "#F94343";
              },
            },
          ],
        },
        {
          label: "硫化氢浓度",
          width: "",
          multistage: true,
          options: [
            {
              prop: "manYdrothion",
              label: "男厕",
              width: "",
              color(val) {
                return "#3B7FFF";
              },
            },
            {
              prop: "womanYdrothion",
              label: "女厕",
              width: "",
              color(val) {
                
                return "#F94343";
                
              },
            },
          ],
        },
        // {
        //   prop: "siteType",
        //   label: "视频在线情况",
        //   width: "",
        //   color(val) {
        //     let color = "";
        //     switch (val) {
        //       case 1:
        //         color = "#FF693B";
        //         break;
        //       case 2:
        //         color = "#1BDC31";
        //         break;
        //     }
        //     return color;
        //   },
        //   formatter(val) {
        //     let str = "";
        //     switch (val.siteType) {
        //       case 1:
        //         str = "已报修";
        //         break;
        //       case 2:
        //         str = "已维修";
        //         break;
        //       default:
        //         str = "暂无数据";
        //         break;
        //     }
        //     return str;
        //   },
        // },
        {
          prop: "workStatusTxt",
          label: "工作状态",
          width: "",
          color(val) {
            
            if (val == '正常'){
              return "#333333";
            } else {
              return "#F94343"
            }
          },
        },
        {
          prop: "deptName",
          label: "所属项目部",
          width: "",
        },
      ],
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      search: {
        toiletName: "",
      },
      show: false,
      departmentOptions: [],
      siteTypes: [
        { id: "1", value: "公厕" },
        { id: "2", value: "转运站" },
      ],
      videoTypeList: [
        { id: "0", value: "球机" },
        { id: "1", value: "枪机" },
        { id: "2", value: "语音设备" },
      ],
      postFormData: {
        videoName: "",
        siteType: "",
        siteName: "",
        videoType: "",
        videoModel: "",
        channelNo: "",
      },
      postFormDataRules: {
        videoName: [
          { required: true, message: "请输入设备名称" },
          { max: 20, message: "最多20个字符" },
        ],
        siteType: [{ required: true, message: "请选择站点类型" }],
        siteName: [{ required: true, message: "请选择站点名称" }],
        videoType: [{ required: true, message: "请选择设备类型" }],
        videoModel: [{ required: true, message: "请输入序列号" }],
        channelNo: [
          { required: true, message: "请输入通道号" },
          { tyep: "regexp", pattern: /^\d+$/, message: "请输入正确的通道号" },
        ],
      },
      isEdit: false,
    };
  },
  mounted() {
    this.getData(this.page, this.size, this.search);
    setTimeout(() => {
      this.loadingData = false;
    }, 800);
  },
  created(){
    // this.getData(this.page, this.size, this.search);

  },
  methods: {
    // 下拉框请求
    department(url) {
      axios.get(url).then((res) => {
        this.departmentOptions = res.map((item) => {
          return {
            id: item.id,
            value: item.siteName,
          };
        });
      });
    },
    getData(page, size, val) {
      axios
        .get(`/toilet/selectToiletPoolList/${page}/${size}`, {
          params: {
            ...val,
          },
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.list;
          // console.log(this.tableData)
          this.total = res.total;
        });
    },
    onSearch(val) {
      console.log(val)
      this.getData(this.page, this.size, val);
    },
    onExport(val) {
     this.$export({
        url:'/toilet/toiletPoolVoExport',
        method: 'get',
        params:{...this.search},
        responseType:'blob'
      }).then(res=>{
        const link = document.createElement('a')
        let blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        let objectUrl = URL.createObjectURL(blob)
        link.href = objectUrl
        link.download = '公厕信息汇总.xlsx'
        link.click()
        URL.revokeObjectURL(objectUrl) // 释放内存
      })
    },
    onReset(val) {
      this.$refs.table.lastEmittedPage();
      this.getData(1, 10, val);
    },
    // 表格操作按钮
    onHandle(data) {
      // opVal ：执行表格编辑删除等操作
      if (data.opVal == "del") {
        this.del(data.data);
      } else if (data.opVal == "edit") {
        this.edit(data.data);
      }
    },
    // 分页事件
    current(val) {
      this.page = val;
      this.getData(this.page, this.size, {});
    },
    cancelAdd() {
      this.$refs.addForm.resetFields();
      this.show = false;
    },
    edit(data) {
      this.isEdit = true;
      this.show = true;
      this.postFormData = Object.assign({}, data);
      this.postFormData.siteType = `${data.siteType}`;
      this.postFormData.videoType = `${data.videoType}`;
      if (data.siteType == "1") {
        this.postFormData.siteName = data.toiletId;
        this.department("/toilet/findAll");
      } else if (data.siteType == "2") {
        this.postFormData.siteName = data.transferPointId;
        this.department("/transferPoint/findAll");
      }
    },
    //下拉框切换类型
    handleRole(val) {
      if (val == "1") {
        this.postFormData.toiletId = this.postFormData.siteName;
        this.department("/toilet/findAll");
      } else if (val == "2") {
        this.postFormData.transferPointId = this.postFormData.siteName;
        this.department("/transferPoint/findAll");
      }
      // let roleObj = this.roleOptions.find(item => {
      //   return item.value === val
      // })
      // this.$set(this.postFormData,'roleId',roleObj.value)
      // this.$set(this.postFormData,'roleName',roleObj.label)
    },
    handleDepartment(val) {
      // let departmenObj = this.departmentOptions.find(item => {
      //   return item.value === val
      // })
      // this.$set(this.postFormData,'departmentId',departmenObj.value)
      // this.$set(this.postFormData,'departmentName',departmenObj.label)
    },
    saveAdd() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let {
            videoName,
            siteType,
            videoType,
            videoModel,
            channelNo,
          } = this.postFormData;
          let dataObj = {
            videoName,
            siteType,
            videoType,
            videoModel,
            channelNo,
          };
          if (this.postFormData.siteType == "1") {
            dataObj.toiletId = this.postFormData.siteName;
            delete dataObj.transferPointId;
          } else if (this.postFormData.siteType == "2") {
            dataObj.transferPointId = this.postFormData.siteName;
            delete dataObj.toiletId;
          }

          axios.post("/video/add", { ...dataObj }).then((res) => {
            if (res == null) {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.cancelAdd();
              this.getData(this.page, this.size, this.search);
            } else {
              this.$message({
                message: "新增失败",
                type: "error",
              });
            }
          });
        }
      });
    },
    update() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let {
            id,
            videoName,
            siteType,
            videoType,
            videoModel,
            channelNo,
          } = this.postFormData;
          let dataObj = {
            id,
            videoName,
            siteType,
            videoType,
            videoModel,
            channelNo,
          };
          if (this.postFormData.siteType == "1") {
            dataObj.toiletId = this.postFormData.siteName;
            delete dataObj.transferPointId;
          } else if (this.postFormData.siteType == "2") {
            dataObj.transferPointId = this.postFormData.siteName;
            delete dataObj.toiletId;
          }
          axios.post("/video/update", { ...dataObj }).then((res) => {
            if (res == null) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.cancelAdd();
              this.getData(this.page, this.size, this.search);
            } else {
              this.$message({
                message: "修改失败",
                type: "error",
              });
            }
          });
        }
      });
    },
    del(data) {
      this.$confirm("此操作将永久删除此设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios.post(`/video/deleteById/${data.id}`).then((res) => {
            if (res == null) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getData(this.page, this.size, this.search);
            } else {
              this.$message({
                message: "删除失败",
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.permissionsBox {
  width: 100%;
  .input {
    width: 100%;
  }
  .drawer-box {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    .title {
      position: relative;
      padding: 20px;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      border-bottom: solid 1px #dadada;
    }
    .table {
      padding: 20px;
      overflow: auto;
    }
    .btn {
      padding: 30px 0;
    }
  }
}
// // 弹窗头部样式
// .re,.rs{
//         display: inline-block;
//         border-top: solid 6px  #2980FF;
//         border-radius:32% ;
//         width: 20px;
//         transform:rotate(120deg);
//         -ms-transform:rotate(120deg); /* Internet Explorer 9*/
//         -moz-transform:rotate(120deg); /* Firefox */
//         -webkit-transform:rotate(120deg); /* Safari 和 Chrome */
//         -o-transform:rotate(120deg); /* Opera */
//         filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
//         position: absolute;
//         top: 48%;
//         left: 34%;
//     }
//     .rs{
//         border-top: solid 6px #3F8CFF;
//         width: 13px;
//         top: 50%;
//         left: 30%;
//     }
//     .ra,.rb{
//         display: inline-block;
//         border-top: solid 6px  #2980FF;
//         border-radius:32% ;
//         width: 20px;
//         transform:rotate(60deg);
//         -ms-transform:rotate(60deg); /* Internet Explorer 9*/
//         -moz-transform:rotate(60deg); /* Firefox */
//         -webkit-transform:rotate(60deg); /* Safari 和 Chrome */
//         -o-transform:rotate(60deg); /* Opera */
//         filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
//         position: absolute;
//         top: 48%;
//         right: 34%;
//     }
//     .rb{
//         border-top: solid 6px #3F8CFF;
//         width: 13px;
//         top: 50%;
//         right: 30%;
//     }
</style>
